import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import FaviconAux from '../images/favicon.png'
import { connect } from "react-redux"

// import Header from "./header"
import Footer from "./footer"
import { useEffect } from "react"

import {CHANGE_APIKEY_ACTION,CHANGE_MAPKEY_ACTION} from '../redux/settingsDucks'
import Modules from "./modules"
import SocialModal from "./socialModal"

const ClientSideOnlyLazy = React.lazy(() =>
  import ("./header")
)

const Layout = ({ dispatch,children,serverData }) => {
  const data = useStaticQuery(graphql`
    query LayoutInfoQuery {
      realEstate {
        favicon
        seo{
          title
        }
        logo
        colors {
          primaryColor
          secondaryColor
          tertiaryColor
        }
        keys {
          tokko
          google_map
        }
        design {
          global {
            file
            upperCase
          }
          h1 {
            file
            upperCase
          }
          h2 {
            file
            upperCase
          }
          h3{
            file
            upperCase
          }
          h4 {
            file
            upperCase
          }
          h5 {
            file
            upperCase
          }
          p {
            file
            upperCase
          }
          icons
          border1
          border2
          border3
          border4
        }
      }
    }
  `)

  const isSSR = typeof window === "undefined"

  const { realEstate: {design:{border1, border2, border3, border4},favicon} } = data

  useEffect(() => {
      dispatch(CHANGE_APIKEY_ACTION(data?.realEstate?.keys?.tokko))
      dispatch(CHANGE_MAPKEY_ACTION(data?.realEstate?.keys?.google_map))
  },[data.realEstate])

  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={favicon || FaviconAux} />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <style type="text/css">{`
          @font-face {
            font-family: h1CustomFont;
            src: url(${data.realEstate.design.h1.file});
          }
          @font-face {
            font-family: h2CustomFont;
            src: url(${data.realEstate.design.h2.file});
          }
          @font-face {
            font-family: h3CustomFont;
            src: url(${data.realEstate.design.h3.file});
          }
          @font-face {
            font-family: h4CustomFont;
            src: url(${data.realEstate.design.h4.file});
          }
          @font-face {
            font-family: h5CustomFont;
            src: url(${data.realEstate.design.h5.file});
          }
          @font-face {
            font-family: pCustomFont;
            src: url(${data.realEstate.design.p.file});
          }
          @font-face {
            font-family: globalCustomFont;
            src: url(${data.realEstate.design.global.file});
          }
      `}</style>
        <style>
        {`\
          :root{\
            --primaryColor: ${data.realEstate.colors.primaryColor};\
            --secondaryColor: ${data.realEstate.colors.secondaryColor};\
            --tertiaryColor: ${data.realEstate.colors.tertiaryColor};\
            --customBorders: ${border1}px ${border2}px ${border3}px ${border4}px;\
            --border1: ${data.realEstate.design.border1}px;\
            --border2: ${data.realEstate.design.border2}px;\
            --border3: ${data.realEstate.design.border3}px;\
            --border4: ${data.realEstate.design.border4}px;\
          }\
        `}
        </style>
      </Helmet>
      <div className="global-wrapper">
        <Modules />
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <ClientSideOnlyLazy siteTitle={data.realEstate.seo?.title || `Title`} logo={data.realEstate.logo} />
          </React.Suspense> )}
        <div className="article">
          <SocialModal/>
        </div>
        <main>{children}</main>
        <Footer/>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(state => ({
}),null)(Layout);
