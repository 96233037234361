import { data } from "jquery";
import {getOperations} from './helper.properties'
import {getGrandfathers, getFathers} from './helper.filters'

export const getCover = (photos) => {
    if(photos?.length > 0){
        if(photos.find(photo => photo.is_front_cover)){
            return photos.find(photo => photo.is_front_cover);
        }
        else{
            return photos[0];
        }
    }
    return {
        original:"https://mediahaus.com.ar/noimage.jpg",
        image:"https://mediahaus.com.ar/noimage.jpg"
    }
}

export const getPhotoNotCover = (photos) => {
    if(photos?.length > 0) {
        if(photos?.length === 1) {
          return (photos.find(photo => photo.is_front_cover))
                ? photos.find(photo => photo.is_front_cover).image
                : photos[0];
        } else {
            return photos.filter(photo => !photo.is_front_cover && photo.description !== "logo" && photo?.description !== "masterplan")[0].image
        }
    } else {
        return {
            original:"https://mediahaus.com.ar/noimage.jpg",
            image:"https://mediahaus.com.ar/noimage.jpg"
        }
    }
}

export const getPreviewSlider = (photos) => {
    let array=[];
    if(photos){
        array = photos.slice(0,3)
        array[0] = getCover(photos)
    }
    return array
}

export const getPhotos = (data) => {
    if(data?.photos?.length > 0){
        return data.photos.filter(photo => !photo.is_blueprint)
    }
    return  [{
                original:"https://mediahaus.com.ar/noimage.jpg",
                image:"https://mediahaus.com.ar/noimage.jpg",
            }]
}

export const getMedia = (data, type) => {
    const noImage = [{
        original:"https://mediahaus.com.ar/noimage.jpg",
        image:"https://mediahaus.com.ar/noimage.jpg",
    }];

    if(data?.photos?.length > 0) {
        if (type === "logo") {
            return data.photos.filter(photo => photo?.description === "logo")
        } else if (type === "masterplan") {
            return data.photos.filter(photo => photo?.description === "masterplan")
        } else {
            return noImage
        }
    }
    return noImage;
} 

export const getVideos = (data) => {
    if(data.videos){
        const list_videos = data.videos.filter(video => video.provider === 'youtube' || video.provider === 'vimeo')
        return list_videos
    }
    return []

}

export const getDescription = (data,characters,notData = false) => {
    if(data.description ){
        if(data.description.length === 0)  return 'Sin descripción'
        const description = data.description.replaceAll("&nbsp;","").slice(0,characters).split("\n")
        return description
    }
    return ['Sin descripción']
}

export const getTextShort = (text,length) => {
    return text?.length > length ? text?.substr(0,length) + '...' : text
}

export const descriptionIsNull = (data) => {
    if(data?.description){
        if(data.description.length === 0)  return true
    }
    return false
}

export const getBrText = (text) => {
    const description = text.split("\n")
    return description
}

export const existDevelopment = (developments) => {
    if(developments?.data?.objects?.length > 0){
        return true
    }
    return false
}

export const existOperation = (operation,filters) => {
    switch(operation){
        case 'venta':
            if(filters?.data?.objects?.operation_types.find(operation => operation.operation_type === 1)) 
                return true
            break;
        case 'alquiler':
            if(filters?.data?.objects?.operation_types.find(operation => operation.operation_type === 2))
                return true
            break;
        case 'alquiler-temporario':
            if(filters?.data?.objects?.operation_types.find(operation => operation.operation_type === 3))
                return true
            break;
    }
    return false
}

export const getWhatsapp = (phone = '',text = '') => {
    return 'https://'+ (window.screen.width < 992 ? 'api' : 'web') + '.whatsapp.com/send?' + (phone !== '' ? "&phone=" + phone : '') + (text !== '' ? '&text=' + text  : '') 
}

export const getMessageWhatsapp = (development = {}, property = {}, url='') => {
    if(development?.name){
      return 'Quería consultar sobre el emprendimiento ' + development?.name + '. ' + '%0A *Url del emprendimiento:* ' + url +'%0A *Url de tokko:* ' + 'https://www.tokkobroker.com/development/'+ development.id +'/'
    }
    else if(property?.id){
        return 'Hola! quería consultar sobre la propiedad en ' + getOperations(property)[0] + ' ubicada en ' + property?.fake_address + '. ' + '%0A *Url de la propiedad:* ' + url +'%0A *Url de tokko:* ' + 'https://www.tokkobroker.com/property/'+ property?.id +'/'
    }
    else {
      return "¡Hola!"
    }
}

export const existArray = (array) => {
    if(array.find(array => array === '')){
        return false
    }
    return true;
}

export const getPhonesText = (phones) => {
    let text = ''
    if(phones){
        phones.forEach((phone,index) => {
            text += phone + (index === phones.length - 1 ? '' : ' / ')
        })
    }
    return text
}

export const getQuality = (photo) => {
    return photo?.image
}

export const getSeoDescription = (url) => {
    
}

export const scrollToElement = (querySelector) => {
    document.querySelector(querySelector)
        .scrollIntoView({ behavior: "smooth" });
}

export const getPriceForMarker = (price) => {
    if(price === undefined || price.length === 0) return ''
    const value = price[0]?.price
    let response = price[0]?.currency === 'USD' ? `USD ` : `ARS `
    if(value?.toString().toLowerCase() === 'precio a consultar'){return 'CONSULTAR'}
    //transform 100.000 to 100k and 1.000.000 to 1m
    if(value > 0){
      if(value >= 1000000){
        if(Number.isInteger(value/1000000)){
          response += `${value/1000000}M`
        }
        else{
          response += `${(value/1000000).toFixed(2)}M`
        }
      }else if(value >= 1000){
        if(Number.isInteger(value/1000)){
          response += `${(value/1000).toFixed(0)}K`
        }
        else{
          response += `${(value/1000).toFixed(2)}K`
        }
      }else{
        response += `${value}`
      }
    }
    return response
  }

export const makeItemsNav = (developments,filters,novelties = [],MediaPro=false, locations = {}, actualFilter = '') => {
    const items = [
        {name:"INICIO",link:"/"},
        {name:"CSHA",link:"/emprendimientos/csha/",logo:true},
        {name:"SHA",link:"/emprendimientos/sha/",logo:true},
        {name:"CAPITAL FEDERAL",link: (Object.keys(locations).length !== 0 && actualFilter.length > 0) ? makeUrlVenta(locations, actualFilter) : '/venta/'},
        {name:"SERVICIOS",link:"/servicios/"},
        {name:"NOVEDADES",link:"/novedades/"},
        {name:"NOSOTROS",link:"/nosotros/"},
        {name:"CONTACTO",link:"/contacto/"},
        {name:("FAVORITOS" + (MediaPro ? "/COMPARAR" : '')),link:"/favoritos/"},
    ]
    
    if(novelties.length === 0){
        items.splice(5,1)
    }
    if(!existOperation("alquiler-temporario",filters)){
        items.splice(4,1)
    }
    if(!existOperation("alquiler",filters)){
        items.splice(3,1)
    }
    if(!existOperation("venta",filters)){
        items.splice(3,1)
    }
    if(!existDevelopment(developments)){
        items.splice(1,1)
    }
    return items
}

export const makeUrlVenta = (locations, filter) => {
    const locationGF = getGrandfathers(locations);
    let locGroup;
    for(const loc of locationGF) {
        if(loc[0] === filter) {
            locGroup = getFathers(loc)
        }
    }
    if(locGroup) {
        let locUrl = "/venta/";
        for (const loc of locGroup) {
            locUrl += loc[0].replaceAll(' ','-').toLowerCase() + ","
        }
        if(locUrl[locUrl.length-1] == ',') {
            locUrl = locUrl.substring(0,locUrl.length - 1);
        }
        return locUrl;
    }
}

export const textTypes = (types) => {
    let text = ''
    types.map((type,index) => {
        text += type.name
        if(type.name[type.name.length-1] === 'n' || type.name[type.name.length-1] === 'l' || type.name[type.name.length-1] === 's'){
            text += 'es'
        }
        else{
            text += 's'
        }
        if(type.name[type.name.length-1] === 'h'){
            text += "s"
        }
        if(index < types.length - 1) text += ', '
    })
    return text
}

export const getFile = (file) => {
    let res    =   {name:"",ext:"",url:""}
    res.name   =   file.file.split("/").at(-1).split(".")[0]
    res.ext    =   file.file.split("/").at(-1).split(".")[1] 
    res.url    =   file.file

    return res
}

export const formatRentTxt = (text) => {
    const textBase = 'Alquiler temporario';
    return (text.includes(textBase)) ? 'Alq. temporario' : text;
}